import "./Footer.css"

const Footer = () => {
  return (
    <div className="Footer">
        <p className="FooterText"></p>
    </div>
  )
}

export default Footer