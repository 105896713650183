import { useRef, useState, useEffect } from "react";
import {
  faCheck,
  faTimes,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Register.css";
import axios from "../../api/axios";
import { Form } from "react-bootstrap";
import { useWindowWidth, useWindowHeight } from "@react-hook/window-size";

const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%._&*]).{8,24}$/;
const EMAIL_REGEX = /^[a-zA-Z0-9._]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/;

const REGISTER_URL = "/register/";

const Register = () => {
  const width = useWindowWidth();
  const height = useWindowHeight();

  const userRef = useRef();
  const errRef = useRef();

  const [username, setUsername] = useState("");
  const [validName, setValidName] = useState(false);
  const [usernameFocus, setUsernameFocus] = useState(false);

  const [password, setPassword] = useState("");
  const [validPassword, setValidPassword] = useState(false);
  const [passwordFocus, setPasswordFocus] = useState(false);

  const [matchPassword, setMatchPassword] = useState("");
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const [first_name, setFirstName] = useState("");
  const [userBio, setUserBio] = useState("");
  const [birthday, setBirthday] = useState("");

  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);

  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);

  const handleBirthdayChange = (event) => {
    setBirthday(event.target.value);
  };

  const handleBioChange = (event) => {
    const text = event.target.value;
    console.log(text.length);
    if (text.length < 512) setUserBio(text);
  };

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(email)); //check if email is valid
  }, [email]);

  useEffect(() => {
    setValidName(USER_REGEX.test(username));
  }, [username]);

  useEffect(() => {
    setValidPassword(PWD_REGEX.test(password));
    setValidMatch(password === matchPassword);
  }, [password, matchPassword]);

  useEffect(() => {
    setErrMsg("");
  }, [username, password, matchPassword]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if button enabled with JS hack
    const v1 = USER_REGEX.test(username);
    const v2 = PWD_REGEX.test(password);
    const v3 = EMAIL_REGEX.test(email);
    if (!v1 || !v2 || !v3) {
      setErrMsg("Invalid Entry");
      return;
    }
    try {
      const response = await axios.post(
        REGISTER_URL,
        JSON.stringify({
          username,
          password,
          email,
          bio: userBio,
          birthday,
          first_name,
        }), //todo match up username with backend
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      console.log(response?.data);
      console.log(response?.access);
      console.log(JSON.stringify(response));
      setSuccess(true);
      //navigate('/login')
      //clear state and controlled inputs
      //need value attrib on inputs for this
      setUsername("");
      setPassword("");
      setMatchPassword("");
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 409) {
        console.log(err.response);
        setErrMsg(err.response?.data);
      } else {
        console.log(err.response?.data);
        setErrMsg("Registration Failed");
      }
      errRef.current.focus();
    }
  };

  return (
    <>
      {success ? (
        <div className="backg">
          <section className="section-login-register">
            <h1> Success! </h1>
            <p>
              <a className="link-login-register" href="/login">
                Sign In
              </a>
            </p>
          </section>
        </div>
      ) : (
        <div className="backg">
          <section className="section-login-register">
            <p
              ref={errRef}
              className={errMsg ? "errmsg" : "offscreen"}
              aria-live="assertive"
            >
              {errMsg}
            </p>
            {height < 600 ? (
              ""
            ) : (
              <h1 className="welcome-header">Welcome to Study Buddy!</h1>
            )}
            {/* <h1 className="welcome-header">Welcome to Study Buddy!</h1> */}
            <form onSubmit={handleSubmit} className="form-login-register">
              <label className="label-login-register" htmlFor="username">
                Username:
                <FontAwesomeIcon
                  icon={faCheck}
                  className={validName ? "valid" : "hide"}
                />
                <FontAwesomeIcon
                  icon={faTimes}
                  className={validName || !username ? "hide" : "invalid"}
                />
              </label>
              <input
                type="text"
                id="username"
                ref={userRef}
                autoComplete="off"
                onChange={(e) => setUsername(e.target.value)}
                value={username}
                required
                aria-invalid={validName ? "false" : "true"}
                aria-describedby="uidnote"
                onFocus={() => setUsernameFocus(true)}
                onBlur={() => setUsernameFocus(false)}
              />
              <p
                id="uidnote"
                className={
                  usernameFocus && username && !validName
                    ? "instructions"
                    : "offscreen"
                }
              >
                <FontAwesomeIcon icon={faInfoCircle} />
                4 to 24 characters.
                <br />
                Must begin with a letter.
                <br />
                Letters, numbers, underscores, hyphens allowed.
              </p>

              <label className="label-login-register" htmlFor="email">
                Email:
                <span className={validEmail ? "valid" : "hide"}>
                  <FontAwesomeIcon icon={faCheck} />
                </span>
                <span className={validEmail || !email ? "hide" : "invalid"}>
                  <FontAwesomeIcon icon={faTimes} />
                </span>
              </label>
              <input
                type="email"
                id="email"
                autoComplete="off"
                onChange={(e) => setEmail(e.target.value)}
                required
                aria-invalid={validEmail ? "false" : "true"}
                aria-describedby="emailnote"
                onFocus={() => setEmailFocus(true)}
                onBlur={() => setEmailFocus(false)}
              />
              <p
                id="emailnote"
                className={
                  emailFocus && email && !validEmail
                    ? "instructions"
                    : "offscreen"
                }
              >
                <FontAwesomeIcon icon={faInfoCircle} />
                Please enter a valid email.
                <br />
              </p>

              <label className="label-login-register" htmlFor="password">
                Password:
                <FontAwesomeIcon
                  icon={faCheck}
                  className={validPassword ? "valid" : "hide"}
                />
                <FontAwesomeIcon
                  icon={faTimes}
                  className={validPassword || !password ? "hide" : "invalid"}
                />
              </label>
              <input
                type="password"
                id="password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                required
                aria-invalid={validPassword ? "false" : "true"}
                aria-describedby="passwordnote"
                onFocus={() => setPasswordFocus(true)}
                onBlur={() => setPasswordFocus(false)}
              />
              <p
                id="passwordnote"
                className={
                  passwordFocus && !validPassword ? "instructions" : "offscreen"
                }
              >
                <FontAwesomeIcon icon={faInfoCircle} />
                8 to 24 characters.
                <br />
                Must include uppercase and lowercase letters, a number and a
                special character.
                <br />
                Allowed special characters:{" "}
                <span aria-label="exclamation mark">!</span>{" "}
                <span aria-label="at symbol">@</span>{" "}
                <span aria-label="hashtag">#</span>{" "}
                <span aria-label="dollar sign">$</span>{" "}
                <span aria-label="percent">%</span>
              </p>

              <label
                className="label-login-register"
                htmlFor="confirm_password"
              >
                Confirm Password:
                <FontAwesomeIcon
                  icon={faCheck}
                  className={validMatch && matchPassword ? "valid" : "hide"}
                />
                <FontAwesomeIcon
                  icon={faTimes}
                  className={validMatch || !matchPassword ? "hide" : "invalid"}
                />
              </label>
              <input
                type="password"
                id="confirm_password"
                onChange={(e) => setMatchPassword(e.target.value)}
                value={matchPassword}
                required
                aria-invalid={validMatch ? "false" : "true"}
                aria-describedby="confirmnote"
                onFocus={() => setMatchFocus(true)}
                onBlur={() => setMatchFocus(false)}
              />
              <p
                id="confirmnote"
                className={
                  matchFocus && !validMatch ? "instructions" : "offscreen"
                }
              >
                <FontAwesomeIcon icon={faInfoCircle} />
                Must match the first password input field.
              </p>
              {height < 844 ? (
                ""
              ) : (
                <label
                  className="label-first-name-register"
                  htmlFor="first_name"
                >
                  First Name:
                </label>
              )}
              {height < 844 ? (
                ""
              ) : (
                <input
                  type="text"
                  id="first_name"
                  autoComplete="off"
                  onChange={(e) => setFirstName(e.target.value)}
                  value={first_name}
                />
              )}
              <Form.Group
                className="birthday-form"
                controlId="validationCustom03"
              >
                <Form.Label>Birthday:</Form.Label>

                <Form.Control
                  required
                  type="date"
                  value={birthday}
                  onChange={handleBirthdayChange}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide your birthday.
                </Form.Control.Feedback>
              </Form.Group>
              {height < 800 ? (
                ""
              ) : (
                <label
                  className="label-bio-register"
                  htmlFor="user_bio"
                  maxLength={32}
                >
                  Tell us about yourself!
                </label>
              )}
              {height < 800 ? (
                ""
              ) : (
                <textarea
                  className="bio-textarea"
                  type="text"
                  rows="2"
                  id="user_bio"
                  onChange={handleBioChange}
                  value={userBio}
                />
              )}
              <button
                disabled={
                  !validName || !validPassword || !validEmail || !validMatch
                    ? true
                    : false
                }
                className="button-login-register"
              >
                Sign Up
              </button>
            </form>
            <p>
              Already registered?
              <br />
              <span className="line">
                {/**can do something else here? but what! */}
                <a className="link-login-register" href="/login">
                  Sign In
                </a>
              </span>
            </p>
          </section>
        </div>
      )}
    </>
  );
};

export default Register;
